import { CAAS_INDEX_SUFFIX, DISABLE_ADS } from "@src/app/constants";
import {
  ButtonWhiteArrowRight,
  ctaIcon,
} from "@src/app/theme/icons/commonIcons";
import {
  BRIGHTCOVE_CONFIG,
  DEFAULT_CONFIG_GAM,
  PREMUTIVE_CONFIG,
} from "@src/pubs/common/config";

import {
  ConfigAffiliatesDeclaration,
  ConfigAuthorProfile,
  ConfigBrightcove,
  ConfigCaas,
  ConfigCategory,
  ConfigContentHub,
  ConfigError,
  ConfigExploreMore,
  ConfigGAM,
  ConfigGTM,
  ConfigHome,
  ConfigLatestStory,
  ConfigLotame,
  ConfigNewsletter,
  ConfigPermutive,
  ConfigSearch,
  ConfigSingleStory,
  ConfigSite,
  ConfigSkimlinks,
  ConfigTag,
  ConfigTeaser,
  ConfigTrackingCode,
  ConfigVideoLanding,
} from "../types/config";
import logoPath from "./logo.svg?url";
import newsletterBg from "./newslettersignupbg.jpg";

export const configSite: ConfigSite = {
  name: "Harper's Bazaar Singapore",
  address:
    import.meta.env.VITE_PUBLIC_BASE_URL || "https://www.harpersbazaar.com.sg", // without trailing slash
  tagline: "Fashion, Beauty, Travel, Parties & Culture",
  description:
    "Sophisticated, elegant and provocative: the latest in fashion &amp; beauty, what to wear and buy now, celebrity style, travel, art &amp; culture news, reviews and videos",
  titleSeparator: "|",
  logoPath,
  twitterSite: "@harpersbazaarsg",
  pageShare: {
    media: [
      {
        name: "Facebook",
      },
      {
        name: "Telegram",
      },
      {
        name: "X",
      },
      {
        name: "Whatsapp",
      },
    ],
  },
};

export { configHeader } from "./config/configHeader";

export const configCaas: ConfigCaas = {
  index: `harpersbazaar${CAAS_INDEX_SUFFIX}`,
  tagIdPrefix: "tag:harpersbazaar-keywords@sph.com.sg,2023:",
  cuePublicationName: "harpersbazaar",
};

export const configGTM: ConfigGTM = {
  container: "GTM-T9QSQQW", //HBS
  siteVerification: "_cGEdj2ZMeQhb_OTIAKvlCGbSxaBwct6M6XrDxywcXM",
};

export const configGAM: ConfigGAM = {
  ...DEFAULT_CONFIG_GAM,
  account: "/5908/hbs",
};

export const configLotame: ConfigLotame = {
  active: !DISABLE_ADS,
  clientId: "12374",
};

export const configPermutive: ConfigPermutive = {
  ...PREMUTIVE_CONFIG,
  active: !DISABLE_ADS,
};

export const configSkimlinks: ConfigSkimlinks = {
  active: false,
  skimlinkId: "",
  geniuslinkId: "",
};

export const configBrightcove: ConfigBrightcove = {
  ...BRIGHTCOVE_CONFIG,
};

export const configSingleStory: ConfigSingleStory = {
  byline: {
    moveToShare: true,
    defaultCredit: "Harper’s Bazaar Singapore Team",
  },
  prefixForDateLine: "published ",
  configShareArticle: {
    label: "Share this article",
    divider: {
      top: true,
    },
  },
  storylineImage: {
    // HBS image links not used because import data misunderstanding
    hyperlink: false,
    // HBS does not allowing editor selection because imported images miss crop selection
    fixedCropSelection: "original",
  },
  displayTags: {
    displayTopTags: false,
    displayTagHeading: true,
  },
};

export const configContentHub: ConfigContentHub = {
  configMasthead: {
    heading: {
      display: true,
    },
  },
  configBodyContent: {
    display: false,
  },
  configShareButton: {
    display: false,
  },
  configEditorsNote: {
    display: true,
  },
  configCallQoute: {
    display: false,
  },
  configFeaturedSection: {
    display: true,
  },
  configDeals: {
    display: false,
  },
  configTopStories: {
    display: true,
    firstItemAnchorBlock: true,
  },
  layout: {
    stories: {
      elements: [
        { name: "contentBlockF", limit: 7 },
        { name: "adsMidContent" },
      ],
    },
  },
};

export const configLatestStory: ConfigLatestStory = {
  latestStoryLimit: 20,
  subAnchorLimit: 4,
  landscapeIndexes: [0, 3],
  mainContentBlockDisplay: "grid",
  mainContentBlockMode: "portrait",
  portraitImageWidth: 300,
  landscapeImageWidth: 630,
  errorLayoutMode: "portrait",
};

export const configCategory: ConfigCategory = {
  pinnedTeasersLimit: 4,
  latestStoriesLimit: 8,
};

export const configTag: ConfigTag = {
  subHeading: "Tag",
  topStoriesLimit: 5,
  listedStoriesLimit: 13,
};

export const configAmazonAffiliates = {};

export const configSearch: ConfigSearch = {};

export const configExploreMore: ConfigExploreMore = {
  heading: "EXPLORE OTHER TOPICS",
};

export const configVideoLanding: ConfigVideoLanding = {
  videoPlaylistFillUpSize: 9,
  videoPath: "/videos",
};

export const configHome: ConfigHome = {
  headingDivider: true,
  viewMore: { icon: ctaIcon },
  latestArticle: {
    landscapeIndexes: [0, 3],
  },
  newsletter: {
    containerImageBg: newsletterBg,
    newsletterTitle:
      "Your front row access to the latest in fashion, beauty and celebrities.",
    newsletterBody:
      "Sign up for the Harper's BAZAAR newsletter. Delivered weekly to your inbox.",
  },
  magazineListing: {
    ctaIcon: ButtonWhiteArrowRight,
  },
  layoutGrid: {
    elements: [
      {
        name: "anchorBlock",
        dataSource: {
          type: "list",
          key: "anchorblock",
          fallback: "latest",
          limit: 1,
        },
      },
      {
        name: "latestArticle",
        dataSource: {
          type: "list",
          key: "pinned",
          fallback: "latest",
          limit: 5,
        },
      },
      {
        name: "contentBlockD",
        dataSource: {
          type: "list",
          key: "trending",
          fallback: "trending",
          title: "Trending",
          refCode: "trending",
          limit: 3,
        },
        alternateBg: true,
      },
      { name: "adUnit" },
      {
        name: "contentBlockB",
        dataSource: {
          type: "tag",
          key: "topic-0",
          refCode: "block-b",
          limit: 3,
          min: 3,
        },
      },
      {
        name: "videoGalleryLandscape",
        dataSource: {
          type: "list",
          key: "videoplaylist",
          refCode: "videoplaylist",
          fallback: "videoplaylist",
          limit: 4,
        },
      },
      {
        name: "contentBlockA",
        dataSource: {
          type: "section",
          key: "fashion",
          refCode: "block-a",
          limit: 5,
        },
      },
      {
        name: "contentBlockF",
        dataSource: {
          type: "section",
          key: "beauty",
          refCode: "block-f",
          limit: 3,
        },
        alternateBg: true,
      },
      { name: "magazineListing" },
      { name: "newsletter" },
    ],
  },
  videoGalleryLandscape: {
    heading: {
      hasArrows: true,
    },
  },
  trendingStoriesPublisher: "hpz",
};

export const configNewsletter: ConfigNewsletter = {
  image: newsletterBg,
  title: `Your front row access to the latest in fashion, beauty and celebrities.`,
  body: `Sign up for the Harper's BAZAAR newsletter. Delivered weekly to your inbox.`,
};

export const configTeaser: ConfigTeaser = {};

export const configTrackingCode: ConfigTrackingCode = {};

export const configAffiliatesDeclaration: ConfigAffiliatesDeclaration = {};

export const configError: ConfigError = {};

export const configAuthorProfile: ConfigAuthorProfile = {
  hideAds: true,
};
