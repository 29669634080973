const EmailIcon = (
  <svg
    viewBox="0 0 24 24"
    height="30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"></path>
  </svg>
);

const FacebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="55"
    viewBox="0 0 74.11 74.1"
  >
    <defs>
      <clipPath id="b">
        <rect
          x="21.83"
          y="9.06"
          width="30.61"
          height="55.98"
          fill="none"
          strokeWidth="0"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#b)">
      <path
        d="m43,26s-1.98-8.81,9.44-7.19v-9.69s-9.29-.29-12.79.44c-3.5.73-9.75,2.2-9.75,16.44h-8.07v9.83h8.07v29.21h13.1v-29.21h9.44v-9.83h-9.44Z"
        fill="#1d1d1b"
        strokeWidth="0"
      />
    </g>
  </svg>
);

const InstagramIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="31"
    viewBox="0 0 89.81 89.62"
  >
    <path
      d="M44.91,0c6.43,0,12.86,0,19.29,0,14.13,0,25.42,11.07,25.53,25.19.1,13.1.11,26.21,0,39.31-.12,14.01-11.46,25.1-25.48,25.11-13.05,0-26.11,0-39.16,0-13.14-.01-24.61-10.81-24.88-23.95-.28-13.88-.28-27.77,0-41.65C.48,10.8,11.84.07,25.04.01c6.63-.03,13.25,0,19.88,0ZM8.45,44.8c0,6.28,0,12.57,0,18.85,0,.98-.02,1.98.15,2.94,1.6,9.02,8.45,14.71,17.64,14.71,12.47,0,24.94,0,37.4,0,10.24,0,17.78-7.48,17.79-17.71.02-12.52.02-25.03,0-37.55-.01-10.24-7.53-17.71-17.77-17.71-12.52,0-25.03,0-37.55,0-10.23,0-17.64,7.38-17.66,17.62-.01,6.28,0,12.57,0,18.85Z"
      strokeWidth="0"
    />
    <path
      d="M44.99,67.98c-12.32.14-22.72-10.04-23.11-22.19-.39-12.24,9.03-23.69,22.87-23.91,12.43-.2,23.05,10.15,23.2,22.43.17,13.12-9.92,23.52-22.96,23.68ZM44.93,59.67c8.11-.03,14.74-6.73,14.71-14.87-.03-7.9-6.75-14.6-14.67-14.62-8.15-.03-14.74,6.67-14.72,14.96.03,7.97,6.69,14.57,14.68,14.54Z"
      strokeWidth="0"
    />
    <path
      d="M63.46,20.92c0-3.17,2.45-5.61,5.58-5.57,3.07.04,5.46,2.47,5.46,5.56,0,3.18-2.41,5.55-5.61,5.53-3.15-.02-5.43-2.35-5.42-5.52Z"
      strokeWidth="0"
    />
  </svg>
);

const LinkedinIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
  >
    <path
      d="M6.07136 27.6365H0.479543V9.58499H6.07136V27.6365ZM3.27117 7.12147C1.47288 7.13005 0 5.67941 0 3.87684C0 2.07426 1.43863 0.59787 3.23692 0.59787C5.0352 0.59787 6.49952 2.03993 6.50809 3.8425C6.50809 5.64508 5.06089 7.1043 3.27117 7.12147ZM27 27.6365H21.4167V18.8468C21.4167 16.7524 21.3739 14.0657 18.5052 14.0657C15.6365 14.0657 15.1484 16.3404 15.1484 18.6923V27.6279H9.56518V9.58499H14.9258V12.0485H15.0029C16.099 10.1687 18.1285 9.0528 20.295 9.13005C25.9553 9.13005 26.9914 12.864 26.9914 17.7137V27.6365H26.9829H27Z"
      fill="black"
    />
  </svg>
);

const TiktokIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="31"
    viewBox="0 0 79.56 89.62"
  >
    <path
      d="M33.59,32.36v15.43c-1.31,0-2.56-.01-3.81,0-6.36.08-11.68,4.58-12.74,10.75-1.08,6.3,2.18,12.18,7.88,14.46,6.76,2.71,17.45-1.26,17.49-12.45.07-19.63.02-39.26.02-58.89V0h14.42c1.14,13.88,9.8,19.85,22.72,21.31v15.2c-3.76.29-7.41-.27-10.89-1.59-3.41-1.29-6.73-2.84-10.29-4.36,0,.66,0,1.33,0,1.99-.04,5.18-.13,10.36-.1,15.54.03,4.24.42,8.49.26,12.72-.41,10.93-5.09,19.59-14.66,25.12-17.08,9.86-39.53-1.07-42.21-20.6C-.7,48.02,11.52,33.21,28.66,32.37c1.62-.08,3.24-.01,4.92-.01Z"
      strokeWidth="0"
    />
  </svg>
);

const TwitterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 74.11 74.1"
  >
    <polygon
      points="13.21 11.37 25.98 11.37 61.72 60.39 48.21 60.39 13.21 11.37"
      fill="#fff"
      stroke="#231f20"
      strokeMiterlimit="10"
    />
    <path
      d="m15.64,12.68h7.79l34.41,46.01h-7.79L15.64,12.68Zm41.92-3.67l-17.86,19.29-14.43-19.29H8.31l22.01,29.43-22.15,23.93h4.99l19.39-20.95,15.66,20.95h16.96l-23.25-31.08,20.61-22.27h-4.98Z"
      fill="#1d1d1b"
      strokeWidth="0"
    />
  </svg>
);

const WhatsappIcon = (
  <svg
    viewBox="0 0 448 512"
    height="30"
    width="30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
  </svg>
);

const YoutubeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="25"
    viewBox="0 0 35 25"
    fill="none"
  >
    <g clipPath="url(#clip0_1913_1978)">
      <path
        d="M34.2682 20.3701C33.8665 21.8449 32.6793 23.0062 31.1739 23.4011C28.4455 24.1172 17.5 24.1172 17.5 24.1172C17.5 24.1172 6.55455 24.1172 3.82614 23.4011C2.32074 23.0062 1.13352 21.8449 0.731818 20.3701C0 17.6953 0 12.1172 0 12.1172C0 12.1172 0 6.53912 0.731818 3.86428C1.13352 2.38945 2.32074 1.22816 3.82614 0.833317C6.55653 0.117188 17.5 0.117188 17.5 0.117188C17.5 0.117188 28.4455 0.117188 31.1739 0.833317C32.6793 1.22816 33.8665 2.38945 34.2682 3.86428C35 6.53719 35 12.1172 35 12.1172C35 12.1172 35 17.6953 34.2682 20.3701ZM13.9205 7.05203V17.1823L23.0682 12.1172L13.9205 7.05203Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1913_1978">
        <rect
          width="35"
          height="24"
          fill="white"
          transform="matrix(1 0 0 -1 0 24.1172)"
        />
      </clipPath>
    </defs>
  </svg>
);

export {
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TiktokIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
};
